var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observertelescope"},[_c('b-form',[_c('EKDialog',{ref:"telescopeDialog",attrs:{"title":"إضافة مجهر","placeholder":"ابحث عن مجهر محددة","btnText":"مجهر جديد"},on:{"ok":function($event){return _vm.onSubmit()},"open":function($event){return _vm.$store.commit('Reset_Telescope_Dto')},"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('EKInputText',{attrs:{"rules":[
                        {
                            type: 'required',
                            message: 'اسم المجهر إجباري'
                        }
                    ],"label":"اسم المجهر","placeholder":"ادخل اسم المجهر","name":"name"},model:{value:(_vm.telescopeDto.name),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "name", $$v)},expression:"telescopeDto.name"}}),_c('EKInputSelect',{attrs:{"label":"السنة","placeholder":"اختر السنة","rules":[
                        {
                            type: 'required',
                            message: 'السنة إجبارية'
                        }
                    ],"options":_vm.years,"name":"year"},model:{value:(_vm.telescopeDto.year),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "year", $$v)},expression:"telescopeDto.year"}}),_c('EKInputSelect',{attrs:{"label":"المادة","placeholder":"اختر المادة","rules":[
                        {
                            type: 'required',
                            message: 'المادة إجبارية'
                        }
                    ],"options":_vm.subjectsList,"name":"subjectId"},model:{value:(_vm.telescopeDto.subjectId),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "subjectId", $$v)},expression:"telescopeDto.subjectId"}}),_c('EKInputSelect',{attrs:{"label":"تصنيفات المجهر","placeholder":"اختر تصنيفات","rules":[
                        {
                            type: 'required',
                            message:
                                'اختر التصنيفات التي يكون المجهر تابع لها'
                        }
                    ],"multiple":"","options":_vm.tagsList,"name":"categories"},model:{value:(_vm.telescopeDto.tagIds),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "tagIds", $$v)},expression:"telescopeDto.tagIds"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }